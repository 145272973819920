<template>
  <div class="container-fluid">
    <ModalForm :visible="show" @close="show = false" :message="modalMsg" />
    <div class="card">
        <div id="report" class="card-body">
            <div class="container-fluid">
              <div style="width: 80px;">
                <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
              </div>
              <div class="headerreport">
                <div class="row">
                  {{ companydata.cmp_nmbre }}
                </div>
                <div class="row">
                  {{ fecha }}
                </div>
              </div>
            </div>
            <br>
            <div class="card-title">
              <h1 class="titlereport">Reporte de solicitudes asignadas</h1>
            </div>
            <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                <table id="reportePqrs" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>No. Radicación</th>
                            <th>Fecha Radicación</th>
                            <th>Otros radicados</th>
                            <th>Proceso misional</th>
                            <th>Tipo solicitud</th>
                            <th class="entidad">Entidad</th>
                            <th class="asunto">Asunto</th>
                            <th>Funcionario asignado</th>
                            <th>Fecha primera actuación</th>
                            <th>Estado actual</th>
                        </tr>
                    </thead>
                    <tbody>
                      <template v-for="(item, index) in items">
                        <tr class="rowstyle">
                          <td>{{ item.radicado }}</td>
                          <td>{{ item.dcm_fcharad | formatDate }}</td>
                          <td>
                            {{ item.text }}
                          </td>
                          <td>{{ item.prc_nmbre }}</td>
                          <td>{{ item.tpq_nmbre }}</td>
                          <td>{{ item.dcm_entidad }}</td>
                          <td>{{ item.dcm_asunto }}</td>
                          <td>{{ item.funcionario }}</td>
                          <td>{{ item.fecha_pa }}</td>
                          <td>{{ item.estado_actual }}</td>
                        </tr>
                      </template>
                    </tbody>
                </table>
            </div>
            <br>
            <!--
            <div v-if="showProgress" style="text-align:center">
              <v-progress-circular
                :size="50"
                :width="5"
                color="blue"
                indeterminate
              ></v-progress-circular>
            </div>
          -->
        </div>
    </div>
    <br>
    <div class="row justify-content-around" align="center">
      <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
      <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
    </div>
  </div>
</template>

<script>
import ModalForm from '../modal/ModalForm.vue';
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate, formatBoolean } from '../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';
import { tiempoUtilizado } from '../../utils';

export default {
  components: { 
    ModalForm,
    //VProgressCircular
  },
  data(){
    return {
      message: '',
      items: [],
      debug: null,
      fecha: null,
      showProgress: false,
      modalMsg: '',
      show: false,
      tpocors: []
    }
  },
  filters: {
    formatDate, formatBoolean
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');

    this.fetchTpocor(err => {
      if (err){
        alert('Error al consultar las clases de documento.');
      } else {
        this.debug = this.$route.params;
        this.searchItems(this.$route.params.query);
      }
    });
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchTpocor(callback){
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;
        return callback(false);
      }).catch((err) => {
        return callback(true);
      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.modalMsg = 'Por favor espere mientras se consultan los datos...';
        this.show = true;

        p.cmp_id = this.$store.state.company;
        //p.usu_id = String(this.$store.state.user);
        this.message = 'Buscando documentos';
        let uri = '/docs/report/solicitudes-asignadas';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then(async (response) => {
          this.items = response.data;

          for (let index = 0; index < this.items.length; index++) {
            let i = this.items[index];
            await this.getRelacionados(i);
            this.$set(this.items, index, i);
          }
          this.show = false;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport(){
      var tableId = 'reporteSolicitudesAsignadas';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        report.push(this.procesarItemExport(this.items[i], i));
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de solicitudes asignadas"],
        [],
        ["No. Radicación", "Fecha Radicación", "Otros radicados", "Proceso misional", "Tipo solicitud", "Entidad", "Asunto", "Funcionario asignado", "Fecha primera actuación", "Estado actual"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A7" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    },
    procesarItemExport(obj, i){
      let item = {};
      item.radicado = obj.radicado;
      item.dcm_fcharad = moment(obj.dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
      item.text = obj.text;
      item.prc_nmbre = obj.prc_nmbre;
      item.tpq_nmbre = obj.tpq_nmbre;
      item.dcm_entidad = obj.dcm_entidad;
      item.dcm_asunto = obj.dcm_asunto;
      item.funcionario = obj.funcionario;
      item.fecha_pa = obj.fecha_pa;
      item.estado_actual = obj.estado_actual;

      return item;      
    },
    async getRelacionados(item) {
      item["estado_actual"] = item?.seguimientoTerminado?.length > 0 ? 'Archivado' : 'En trámite';

      /* UTILIZANDO NUEVA CONSULTA
      let uri = '/docs/report/solicitudes-asignadas-expediente';
      var relacionados = await this.axios.post(uri, { 'cmp_id': this.$store.state.company, 'dcm_expediente': item.dcm_expediente, '_id': item._id });
      var a = relacionados.data;
      */
      
      var a = item.relacionados;
      var text = '';
      if (!a) {
        return;
      }

      for (let index = 0; index < a.length; index++) {
        let i = a[index];
        var tpocor = this.tpocors.find(t => t._id === i.tcr_id);
        i.tcr_cdgo = tpocor?.tcr_cdgo;

        text += `${ i.tcr_cdgo }-${ i.dcm_ano }-${ i.dcm_cdgo }`;
        if (index < a.length - 1) {
          text += ', ';
        }
      }

      item.text = text;
      var tpocor = this.tpocors.find(t => t.tcr_direccion === 'E');
      var enviada = a.find(t => t.tcr_id === tpocor?._id);
      item.fecha_pa = enviada ? moment(enviada?.dcm_fcharad).format('DD/MMM/YYYY') : '';
    }

  } // END METHODS
}
</script>

<style>

  @import '../../../public/css/report.css';

</style>